// Helper function to format specialty and job title combinations
export const formatSpecialtyJobTitle = (specialty, jobTitle) => {
    // If either value is missing, just return what we have
    if (!specialty || !jobTitle) {
      return specialty || jobTitle || '';
    }
    
    // Check for the specific combinations
    if (specialty === "Social Work" && jobTitle === "Social Worker") {
      return "Social Worker";
    }
    
    if (specialty === "Pharmacy" && jobTitle === "Pharmacist") {
      return "Pharmacist";
    }
    
    if (specialty === "Radiology" && jobTitle === "Rad Tech") {
      return "Rad Tech";
    }
    
    if (specialty === "Radiation Therapy" && jobTitle === "Radiation Therapist") {
      return "Radiation Therapist";
    }
    
    if (specialty === "Dietary" && jobTitle === "Dietician") {
      return "Dietician";
    }

    if (specialty === "Registered Dietician") {
      return "Dietician";
    }

    if (specialty === "Respiratory Therapy" && jobTitle === "Respiratory Therapist") {
      return "Respiratory Therapist";
    }
    
    if (specialty === "OT" && jobTitle === "OT") {
      return "OT";
    }
    
    // Default case: return the combined value
    return `${specialty} ${jobTitle}`.trim();
  };