import React, { useEffect, useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

// --- Existing Keyframes ---

// Animation for the gradient background
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// Keyframe for the letter highlight animation
const letterHighlight = keyframes`
  0% {
    color: white;
    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  11.1%, 44.4% {
    color: #f47424; /* Orange color */
    text-shadow: 0 0 8px rgba(255, 105, 180, 0.5);
  }
  50%, 100% {
    color: white;
    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

// --- New Heartbeat Animation Keyframes ---
// Assuming a path length of roughly 215 for the heartbeat SVG
const heartbeatPulse = keyframes`
  0% {
    stroke-dashoffset: 215; /* Start fully hidden (offset = length) */
    opacity: 0;
  }
  10% {
    stroke-dashoffset: 0; /* Fully drawn */
    opacity: 1;
  }
  20% {
    stroke-dashoffset: -215; /* Start disappearing from the beginning */
    opacity: 0;
  }
  100% {
    stroke-dashoffset: -215; /* Stay in the fully disappeared state for the rest of the animation */
    opacity: 0;
  }
`;

// Keyframe for Image Sweep Animation (Diagonal Sweep L-R, Start/End Hidden)
const imageSweep = keyframes`
  0% {
    /* Start with the DIAGONAL band completely off-screen to the left */
    clip-path: polygon(-30% 0%, 0% 0%, -30% 100%, -60% 100%);
  }
  100% {
    /* End with the DIAGONAL band completely off-screen to the right */
    clip-path: polygon(130% 0%, 160% 0%, 130% 100%, 100% 100%);
  }
`;

// --- Styled Components ---

// Styled component for each letter
const AnimatedLetter = styled.span`
  display: inline-block; /* Keep inline-block for animation timing */
  animation: ${letterHighlight} 9s infinite;
  animation-delay: calc(var(--index) * (1s / var(--total-letters)));
`;

// Animated gradient
const HeroSection = styled.section`
  background: linear-gradient(
    135deg,
    #28346c 0%,
    #2c397a 25%,
    #232c5c 50%,
    #2c397a 75%,
    #28346c 100%
  );
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  position: relative;
  overflow: visible; /* Keep visible for dropdowns */
  z-index: 15;

  /* Pseudo-element for the sweeping background image */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Use the imageUrl prop passed from the component */
    background-image: url(${props => props.imageUrl});
    background-size: cover;
    background-position: center center;
    opacity: 0.1; /* Adjust transparency as needed */
    z-index: 16; /* Layer above gradient, below pattern/content */
    pointer-events: none;
    /* Apply the sweeping animation */
    animation: ${imageSweep} 25s linear infinite;
    /* Add a subtle transition for the background image change */
    transition: background-image 0.5s ease-in-out;
  }

  @media (max-width: 768px) {
    padding: 4rem 1.5rem;
  }
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 20;
`;

// --- New Heartbeat SVG Component ---
const HeartbeatSVG = styled.svg`
  width: 60px;
  height: 40px;
  margin: 0 15px;
  overflow: visible;
  flex-shrink: 0;

  path {
    fill: none;
    stroke: #f47424;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 215;
    stroke-dashoffset: 215;
    /* Use a longer duration like 6s or 8s to have more time between beats */
    animation: ${heartbeatPulse} 10s ease-in-out infinite;
  }

  @media (max-width: 768px) {
    width: 40px;
    height: 30px;
    margin: 0 5px;
  }
`;

// --- Modified Title Containers ---

// Wrapper for Title and Heartbeats (Desktop)
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem; /* Keep original margin */

  @media (max-width: 768px) {
      /* Styles removed as mobile structure is handled differently now */
      margin-bottom: 2rem; /* Keep original mobile margin */
      /* Flex direction column is now default behavior of HeroTitle on mobile */
  }
`;

// Title with responsive styling
const HeroTitle = styled.h1`
  font-size: 2.8rem;
  font-weight: 700;
  color: white;
  position: relative;
  text-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 0; /* Reset margin */
  padding: 0 10px; /* Add some padding so heartbeats don't touch */
  white-space: nowrap; /* Prevent wrapping on desktop */

  @media (max-width: 768px) {
    font-size: 2.2rem;
    line-height: 1.3;
    text-align: center;
    width: 100%;
    transform: scale(1.25); // Apply scale to the whole block
    margin-bottom: 0; /* Reset margin */
    padding: 0;
    white-space: normal; /* Ensure wrapping is allowed on mobile */
    /* Display block implicitly handles stacking of lines */
  }
`;

// Styled components for each part of the title on mobile
const TitleFirstLine = styled.div`
   display: block; /* Ensure it takes its own line */
   margin-bottom: 0.25rem; /* Add slight space below */
`;

const TitleSecondLine = styled.div`
   display: block; /* Takes its own line within the flex wrapper */
   /* No margin needed here, handled by wrapper */
`;

const TitleThirdLine = styled.div`
   display: block; /* Ensure it takes its own line */
   margin-top: 0.25rem; /* Add slight space above */
`;

// *** NEW: Wrapper for the second line and heartbeats on mobile ***
const MobileSecondLineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Ensure it spans width for centering */
  margin: 0.25rem 0; /* Add some vertical spacing */
`;

// --- Remaining Styled Components (Largely Unchanged) ---

const HeroSubtitle = styled.p`
  font-size: 1.3rem;
  line-height: 1.5;
  margin-bottom: 2.5rem;
  color: rgba(255, 255, 255, 0.95);
  position: relative;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }
`;

const BackgroundPattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(rgba(255, 255, 255, 0.15) 2px, transparent 2px);
  background-size: 30px 30px;
  opacity: 0.2;
  pointer-events: none;
  z-index: 17; /* Ensure pattern is above the sweeping image */
`;

const FilterBar = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: 20;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.75rem;
  }
`;

const FilterItem = styled.div`
  position: relative;
  flex: 1;
  z-index: 20;

  /* Updated icon styling for better alignment */
  > svg:first-of-type { /* Target the icon specifically */
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #666; /* Default color */
    z-index: 1; /* Ensure icon is above input background */
    pointer-events: none; /* Prevent icon from blocking input clicks */
    stroke: #f47424; /* Specific stroke for these icons */
    display: flex; /* Added for better alignment */
    align-items: center; /* Ensure vertical centering */
    justify-content: center; /* Center horizontally */
  }
`;

const FilterInput = styled.input`
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.5rem; /* Ensure padding for icon */
  border-radius: 4px;
  border: none;
  font-size: 1rem;
  color: #2c3e50;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  line-height: normal; /* Added to ensure consistent text baseline */

  &:focus {
    outline: none;
    box-shadow: 0 4px 12px rgba(255, 92, 184, 0.2), 0 0 0 2px rgba(255, 92, 184, 0.1);
    transform: translateY(-1px);
  }
`;

const ChevronDown = styled.span`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 0.8rem;
  color: #666;
  display: flex; /* Added for better alignment */
  align-items: center; /* Ensure vertical centering */
  height: 100%; /* Match container height */
  line-height: 1; /* Prevent line-height issues */
`;

const Overlay = styled.div`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
`;

const FilterOptions = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  max-width: 350px;
  width: 90%;
  max-height: 70vh;
  border-radius: 4px;
  z-index: 100;
  padding: 1rem;
  overflow-y: auto;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 4px;
  }
`;

const DesktopFilterOptions = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  background: white;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-top: 5px;
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  color: #28346c;
  font-size: 1.25rem;
  cursor: pointer;

  @media (max-width: 768px) {
    position: fixed;
    top: 0.75rem;
    right: 0.75rem;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;

    &:hover {
      background-color: rgba(40, 52, 108, 0.1);
    }
  }
`;

const FilterOption = styled.label`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #2c3e50;
  cursor: pointer;
  padding: 8px;

  &:hover {
    background-color: #f5f5f5;
    color: #28346c;
  }

  @media (max-width: 768px) {
    padding: 12px 8px;
    font-size: 1rem;
  }
`;

const FilterCheckbox = styled.input`
  margin-right: 0.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    transform: scale(1.2);
    margin-right: 0.75rem;
  }
`;

const NoResults = styled.div`
  padding: 0.6rem 1rem;
  color: #7f8c8d;
  font-style: italic;
`;

const SearchButton = styled.button`
  background: linear-gradient(135deg, #28346c, #3a4785);
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  align-self: flex-end;
  margin-top: .;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
    transition: all 0.6s ease;
  }

  svg {
    margin-right: 0.5rem;
    display: block; /* Added for consistent sizing */
    position: relative; /* Helps with alignment */
    
  }

  &:hover {
    background: linear-gradient(135deg, #3a4785, #28346c);
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(40,52,108,0.3);
  }

  &:hover::before {
    left: 100%;
  }

  &:active {
    transform: translateY(-1px);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }
`;  

const Button = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #ffffff, #f0f2f8);
  color: #28346c;
  padding: 0.875rem 1.75rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: scale(1.25);
  margin-top: 1.5rem;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(40, 52, 108, 0.1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(40, 52, 108, 0.05), transparent);
    transition: all 0.6s ease;
  }

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background: linear-gradient(135deg, #f0f2f8, #ffffff);
    color: #3a4785;
    transform: scale(1.25) translateY(-2px);
    box-shadow: 0 6px 15px rgba(40, 52, 108, 0.25);
    border-color: rgba(40, 52, 108, 0.2);
    text-decoration: none;
  }

  &:hover::before {
    left: 100%;
  }

  &:active {
    transform: scale(1.25) translateY(-1px);
  }

  @media (max-width: 768px) {
    transform: scale(1.1); /* Adjusted mobile scale */
    padding: 0.75rem 1.5rem;
    font-size: 1rem;

    &:hover, &:active {
      transform: scale(1.1) translateY(-2px);
    }
  }
`;

// Utility to check if mobile
const isMobileDevice = () => typeof window !== 'undefined' && window.innerWidth <= 768;

// --- Component Function ---
const EnhancedHeroSection = ({
  jobTitleSearch,
  setJobTitleSearch,
  stateSearch,
  setStateSearch,
  activeFilter,
  setActiveFilter,
  handleSearch,
  loading,
  getFilteredOptions,
  getStateDisplayName
}) => {
  // For the animated letters in "Next Career"
  const nextCareerText = 'Next Career';
  const totalLetters = nextCareerText.length;

  // Track which filter we are showing in the overlay
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(isMobileDevice()); // State for mobile check

  // Create refs for the dropdown containers
  const jobTitleFilterRef = useRef(null);
  const stateFilterRef = useRef(null);

  // Heartbeat SVG Path definition
  const heartbeatPath = "M0,20 H30 L35,10 L45,30 L50,20 H80"; // Example path
  
  // --- State for Background Image Cycling ---
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // Define the image paths (ensure these exist in your /public folder or are valid URLs)
  const images = [
    '/heropeople.jpg',
    '/heropeople2.jpg',
    '/heropeople3.jpg'
  ];
  // CSS animation duration in seconds
  const animationDurationSeconds = 25;
  // Convert to milliseconds for JS
  const animationDurationMs = animationDurationSeconds * 1000;
  // Small delay (in ms) to add after animation completes before changing image
  const imageChangeDelayMs = 100; // e.g., 100ms delay

  // Update mobile state on resize
  useEffect(() => {
    const handleResize = () => {
      const mobileCheck = isMobileDevice();
      setIsMobile(mobileCheck);
      if (!mobileCheck) { // If resizing to desktop, hide overlay
        setOverlayVisible(false);
        setActiveFilter(null); // Also reset active filter
      }
    };
    window.addEventListener('resize', handleResize);
    // Initial check
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [setActiveFilter]); // Added setActiveFilter dependency

  // Effect to handle background image cycling
  useEffect(() => {
    // Set up an interval to change the image index
    const intervalId = setInterval(() => {
      // Cycle to the next image index, wrapping around
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, animationDurationMs + imageChangeDelayMs);

    // Cleanup: Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [images.length, animationDurationMs, imageChangeDelayMs]);

  // Add click outside handler for desktop
  useEffect(() => {
    // Only run if on desktop and a filter dropdown is active
    if (!isMobile && activeFilter !== null) {
      const handleClickOutside = (event) => {
        // Check if the click is outside the relevant filter item's ref
        const jobTitleClickedOutside = activeFilter === 'jobTitle' &&
                                       jobTitleFilterRef.current &&
                                       !jobTitleFilterRef.current.contains(event.target);
        const stateClickedOutside = activeFilter === 'state' &&
                                    stateFilterRef.current &&
                                    !stateFilterRef.current.contains(event.target);

        if (jobTitleClickedOutside || stateClickedOutside) {
          // Check if the click was on the title/heartbeat area to prevent closing
          const titleWrapper = event.target.closest('[data-title-wrapper]');
          if (!titleWrapper) {
              setActiveFilter(null); // Close the dropdown
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [activeFilter, setActiveFilter, isMobile]); // Added isMobile dependency


  const handleFilterFocus = (filterName) => {
    if (!loading) {
      setActiveFilter(filterName);
      if (isMobile) { // Use state variable
        setOverlayVisible(true);
      }
    }
  };


  const closeOverlay = () => {
    setActiveFilter(null);
    setOverlayVisible(false);
  };

  // Search handling to navigate with collapsed filters
  const handleSearchWithCollapsedFilters = () => {
    handleSearch(); // Assuming this function handles the core search logic
    if (jobTitleSearch || stateSearch) {
      // Construct the URL carefully
      const queryParams = [];
      queryParams.push('collapsed=true'); // Always include collapsed flag
      if (jobTitleSearch) {
        queryParams.push(`title=${encodeURIComponent(jobTitleSearch)}`);
      }
      if (stateSearch) {
        queryParams.push(`state=${encodeURIComponent(stateSearch)}`);
      }
      // Navigate using window.location or preferably react-router's navigate function if available
      window.location.href = `/jobs?${queryParams.join('&')}`;
    } else {
        // If no search terms, maybe just go to /jobs or stay put?
    }
    setActiveFilter(null); // Close dropdowns after search
    setOverlayVisible(false);
  };

  return (
    <HeroSection imageUrl={images[currentImageIndex]}>
      <BackgroundPattern />

      {/* Overlay and Mobile Filter Options */}
      <Overlay visible={overlayVisible} onClick={closeOverlay} />
      <FilterOptions
        visible={overlayVisible && (activeFilter === 'jobTitle' || activeFilter === 'state')}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
      >
        <CloseButton onClick={closeOverlay}>×</CloseButton>

        {/* Mobile Job Title Options */}
        {activeFilter === 'jobTitle' && (
          <>
            {getFilteredOptions('jobTitle').length > 0 ? (
              getFilteredOptions('jobTitle').map((title) => (
                <FilterOption key={title}>
                  <FilterCheckbox
                    type="checkbox"
                    checked={jobTitleSearch === title}
                    onChange={() => {
                      setJobTitleSearch(title === jobTitleSearch ? '' : title);
                    }}
                  />
                  {title}
                </FilterOption>
              ))
            ) : (
              <NoResults>No matching job titles</NoResults>
            )}
          </>
        )}

        {/* Mobile State Options */}
        {activeFilter === 'state' && (
          <>
            {getFilteredOptions('state').length > 0 ? (
              getFilteredOptions('state').map((abbr) => (
                <FilterOption key={abbr}>
                  <FilterCheckbox
                    type="checkbox"
                    checked={stateSearch === abbr}
                    onChange={() => {
                      setStateSearch(abbr === stateSearch ? '' : abbr);
                    }}
                  />
                  {getStateDisplayName(abbr)}
                </FilterOption>
              ))
            ) : (
              <NoResults>No matching states</NoResults>
            )}
          </>
        )}
         <button onClick={closeOverlay} style={{marginTop: '1rem', padding: '0.5rem', background: '#28346c', color: 'white', border: 'none', borderRadius: '4px'}}>Done</button>
      </FilterOptions>

      <HeroContent>
        {/* Title Wrapper for Desktop and Mobile */}
         <TitleWrapper data-title-wrapper> {/* Keep data attribute for click outside logic */}
            {/* Left Heartbeat (Desktop Only) */}
            {!isMobile && (
                <HeartbeatSVG viewBox="0 0 80 40">
                    <path d={heartbeatPath} style={{ transform: 'scaleX(-1)', transformOrigin: 'center' }} />
                </HeartbeatSVG>
            )}

            {/* Combined Title Logic */}
            <HeroTitle>
                {/* Desktop Title */}
                {!isMobile && (
                    <>
                        Find Your{' '}
                        {nextCareerText.split('').map((letter, index) => (
                            <AnimatedLetter
                                key={index}
                                style={{
                                    '--index': index,
                                    '--total-letters': totalLetters
                                }}
                            >
                                {letter === ' ' ? '\u00A0' : letter}
                            </AnimatedLetter>
                        ))}{' '}
                        Opportunity
                    </>
                )}
                {/* Mobile Title */}
                {isMobile && (
                    <>
                        <TitleFirstLine>Find Your</TitleFirstLine>
                        {/* *** NEW: Wrapper for second line + SVGs *** */}
                        <MobileSecondLineWrapper>
                             <HeartbeatSVG viewBox="0 0 80 40">
                                <path d={heartbeatPath} style={{ transform: 'scaleX(-1)', transformOrigin: 'center' }} />
                            </HeartbeatSVG>
                            <TitleSecondLine>
                                {nextCareerText.split('').map((letter, index) => (
                                    <AnimatedLetter
                                        key={index}
                                        style={{
                                            '--index': index,
                                            '--total-letters': totalLetters
                                        }}
                                    >
                                        {letter === ' ' ? '\u00A0' : letter}
                                    </AnimatedLetter>
                                ))}
                            </TitleSecondLine>
                             <HeartbeatSVG viewBox="0 0 80 40">
                                <path d={heartbeatPath} />
                            </HeartbeatSVG>
                        </MobileSecondLineWrapper>
                        <TitleThirdLine>Opportunity</TitleThirdLine>
                    </>
                )}
            </HeroTitle>

            {/* Right Heartbeat (Desktop Only) */}
             {!isMobile && (
                <HeartbeatSVG viewBox="0 0 80 40">
                    <path d={heartbeatPath} />
                </HeartbeatSVG>
            )}
        </TitleWrapper>

        {/* Mobile Heartbeat Container REMOVED */}

        <HeroSubtitle>
          Browse our available positions and join our team of talented professionals:
        </HeroSubtitle>

        <FilterBar>
          {/* JOB TITLE FILTER */}
          <FilterItem ref={jobTitleFilterRef}>
             {/* Icon remains absolute positioned */}
            <svg
              xmlns="http://www.w3.org/2000/svg" width="16" height="16"
              viewBox="0 0 24 24" fill="none"
              strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            >
              <path d="M12 20h9"></path>
              <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
            </svg>
            <FilterInput
              type="text"
              placeholder={loading ? "Loading options..." : "Your job title"}
              value={jobTitleSearch}
              onChange={(e) => {
                 if (!isMobile) { // Only allow typing on desktop
                    setJobTitleSearch(e.target.value);
                    setActiveFilter('jobTitle'); // Keep dropdown open while typing
                 }
              }}
              onMouseDown={() => { if (isMobile) handleFilterFocus('jobTitle'); }}
              onFocus={() => { if (!isMobile) handleFilterFocus('jobTitle'); }}
              readOnly={isMobile}
              disabled={loading}
            />
            <ChevronDown>▼</ChevronDown>

            <DesktopFilterOptions visible={!isMobile && activeFilter === 'jobTitle'}>
              {getFilteredOptions('jobTitle').length > 0 ? (
                getFilteredOptions('jobTitle').map((title) => (
                  <FilterOption key={title} onMouseDown={(e) => {
                      e.preventDefault();
                      setJobTitleSearch(title === jobTitleSearch ? '' : title);
                      setActiveFilter(null);
                  }}>
                    <FilterCheckbox
                      type="checkbox"
                      checked={jobTitleSearch === title}
                      readOnly
                    />
                    {title}
                  </FilterOption>
                ))
              ) : (
                <NoResults>No matching job titles</NoResults>
              )}
            </DesktopFilterOptions>
          </FilterItem>

          {/* STATE FILTER */}
          <FilterItem ref={stateFilterRef}>
             {/* Icon remains absolute positioned */}
            <svg
              xmlns="http://www.w3.org/2000/svg" width="16" height="16"
              viewBox="0 0 24 24" fill="none"
              strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            >
              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
              <circle cx="12" cy="10" r="3"></circle>
            </svg>
            <FilterInput
              type="text"
              placeholder={loading ? "Loading options..." : "Location"}
              value={!isMobile ? stateSearch : (stateSearch ? getStateDisplayName(stateSearch) : '')}
              onChange={(e) => {
                  if (!isMobile) {
                    setStateSearch(e.target.value);
                    setActiveFilter('state');
                  }
              }}
              onMouseDown={() => { if (isMobile) handleFilterFocus('state'); }}
              onFocus={() => { if (!isMobile) handleFilterFocus('state'); }}
              readOnly={isMobile}
              disabled={loading}
            />
            <ChevronDown>▼</ChevronDown>

            <DesktopFilterOptions visible={!isMobile && activeFilter === 'state'}>
              {getFilteredOptions('state').length > 0 ? (
                getFilteredOptions('state').map((abbr) => (
                  <FilterOption key={abbr} onMouseDown={(e) => {
                      e.preventDefault();
                      setStateSearch(abbr === stateSearch ? '' : abbr);
                      setActiveFilter(null);
                  }}>
                    <FilterCheckbox
                      type="checkbox"
                      checked={stateSearch === abbr}
                      readOnly
                    />
                    {getStateDisplayName(abbr)}
                  </FilterOption>
                ))
              ) : (
                <NoResults>No matching states</NoResults>
              )}
            </DesktopFilterOptions>
          </FilterItem>

          <SearchButton onClick={handleSearchWithCollapsedFilters} disabled={loading}>
            <svg
              xmlns="http://www.w3.org/2000/svg" width="16" height="16"
              viewBox="0 0 24 24" fill="none" stroke="#f47424"
              strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
            Search
          </SearchButton>
        </FilterBar>

        <Button to="/jobs" onClick={() => window.scrollTo(0, 0)}>
          <svg
            xmlns="http://www.w3.org/2000/svg" width="16" height="16"
            viewBox="0 0 24 24" fill="none" stroke="#f47424"
            strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
          >
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
            <circle cx="12" cy="12" r="3"></circle>
          </svg>
          View All Open Positions
        </Button>
      </HeroContent>
    </HeroSection>
  );
};

export default EnhancedHeroSection;